import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Router} from "../Routing/Router";
import {Portfolio} from "./Portfolio";
import React from "react";

export const MenuNav =()=>{

    return <div className="header">
        <>
            <Navbar expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
                <Container >
                    <Navbar.Brand href="home">Małgorzata Jurczak</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav " />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="me-auto active" >
                            <NavDropdown title="O mnie" id="basic-nav-dropdown" >
                                <NavDropdown.Item href="education">Wykształcenie</NavDropdown.Item>
                                <NavDropdown.Item href="experience">Doświadzenie </NavDropdown.Item>
                                <NavDropdown.Item href="technology">Technologie</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="freetime">
                                    W wolnym czasie
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="portfolio">Portfolio</Nav.Link>
                            <Nav.Link href="contact">Kontakt</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    </div>
}
